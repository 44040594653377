.App {
  text-align: center;
}

.Content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 20vh;
  margin-top: 12vh;
}

.Book {
  padding: 2em;
  margin: 2em;
  border: 2px solid gray;
}

.User {
  border: 2px solid gray;
  padding: 1em;
  margin-bottom: 2em;
  border-radius: 10px;
}

.AddBook {
  display: flex;
  align-items: center;
  min-height: 20vh;
  padding: 7em;
}

.home {
  display: inline-grid;
  width: 100%;
}
.btnContainer {
  margin: 0.35em;
  display: flex;
  justify-self: flex-end;
}

.operating {
  background-color: green;
  color: white;
  padding: 0.3em 0.5em;
  margin: 0.5em;
  display: flex;
  text-decoration: none;
  border-radius: 2em;
  font-weight: bolder;
  justify-content: center;
}

.maintenance {
  background-color: orange;
  color: white;
  padding: 0.3em 0.5em;
  margin: 0.5em;
  display: flex;
  text-decoration: none;
  border-radius: 2em;
  font-weight: bolder;
  justify-content: center;
}
